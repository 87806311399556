<template>
      <p>Powered by 
            <i title="vuejs" class="devicon-vuejs-plain colored"></i>
            <i title="TypeScript" class="devicon-typescript-plain colored"></i>
            <i title="Ubuntu" class="devicon-ubuntu-plain colored"></i>
            <i title="webpack" class="devicon-webpack-plain colored"></i>
            <i title="NPM" class="devicon-npm-original-wordmark colored"></i>
            <i title="GitHub" class="devicon-github-original"></i>
      </p>
      <p><a href="https://github.com/kaovilai/kaovilai.pw">See this code on GitHub!</a></p>
</template>

<script>
export default {
}
</script>

<style scoped>
i {
  margin: 0px 3px;
}
</style>


import { defineComponent } from "vue"
import TypingComponent from "./TypingComponent.vue"
export default defineComponent({
  components: { TypingComponent },
  name: 'HelloWorld',
  data () {
    return {
      title: 'Hey there',
      underConstructionTxt: ' -- Under Active Development',
    }
  },
  
})
